import React from 'react';
import { useQuery } from 'cccisd-react-query';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import Table from 'cccisd-graphql-table';
import IconLink from 'cccisd-icons/link';
import Loader from 'cccisd-loader';
import Tooltip from 'cccisd-tooltip';
import { copyOnClick } from '../../helpers';
import initialDataQuery from './initialData.graphql';
import employeesQuery from './employees.graphql';
import style from './style.css';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

const OrganizationAdminDashboard = () => {
    const fetchData = async () => {
        const response = await client.query({
            query: initialDataQuery,
            variables: {
                groupId: Fortress.user.acting.group_id,
            },
            fetchPolicy: 'network-only',
        });

        const registerHash = _get(response, 'data.groups.site.fields.registerHash', '') || '';
        const employeeLimit = _get(response, 'data.groups.site.fields.employeeLimit', null);
        const employeeCount = _get(response, 'data.groups.site.childRoles.instructorCount', 0) || 0;
        const courseDeploymentId = _get(response, 'data.flows.deployment.deploymentId', -1) || -1;
        return { registerHash, employeeLimit, employeeCount, courseDeploymentId };
    };

    const { isLoading, error, data } = useQuery(['initialLoad'], fetchData);

    function renderCourseStatus({ value }) {
        let className;
        switch (value) {
            case 'Complete':
                className = style.complete;
                break;
            case 'In Progress':
                className = style.inProgress;
                break;
            case 'Not Started':
                className = style.notStarted;
                break;
            default:
        }
        return <span className={`${className} ${style.status}`}>{value}</span>;
    }

    function renderEmployeeStatement() {
        if (!data.employeeLimit) {
            return null;
        }

        const limit = parseInt(data.employeeLimit, 10);
        const current = parseInt(data.employeeCount, 10);
        if (current > limit) {
            return (
                <p>
                    {data.employeeCount} employees have registered (<b>0 available</b>)
                </p>
            );
        }

        return (
            <p>
                {current} out of {limit} employees have registered (<b>{limit - current} available</b>)
            </p>
        );
    }

    if (isLoading) {
        return <Loader loading />;
    }

    if (error) {
        let errorMsg = error.message || error;
        if (typeof errorMsg !== 'string') {
            errorMsg = 'Could not load Dashboard at this time. Please contact an administrator for support.';
        }
        return <p className="alert alert-danger">{errorMsg}</p>;
    }

    const registerLink = data.registerHash && Boilerplate.url('') + 'register/' + data.registerHash;
    const columns = [
        { name: 'user.username', label: 'Username', sort: true, filter: true },
        {
            name: 'user.firstName',
            label: 'First Name',
            sort: true,
            filter: true,
        },
        { name: 'user.lastName', label: 'Last Name', sort: true, filter: true },
        {
            name: 'status',
            label: 'Status',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                placeholder: '-- Choose one --',
                options: [
                    { label: 'Complete', value: 'Complete' },
                    { label: 'In Progress', value: 'In Progress' },
                    { label: 'Not Started', value: 'Not Started' },
                ],
            },
            render: renderCourseStatus,
        },
        {
            name: 'assignmentProgress.completedDate',
            label: 'Completion Date',
            sort: true,
            filter: true,
            filterSettings: { type: 'date' },
        },
        {
            name: 'user.lastLoginDate',
            label: 'Last Login',
            sort: true,
            filter: true,
            filterSettings: { type: 'date' },
        },
    ];

    return (
        <div>
            {registerLink && (
                <div className={style.registration}>
                    <h3>Registration Link</h3>
                    <div className={style.linkContainer}>
                        <Tooltip title="Copy Link">
                            <button type="button" className="btn btn-info" onClick={() => copyOnClick(registerLink)}>
                                <IconLink />
                                &nbsp;Copy
                            </button>
                        </Tooltip>
                        &nbsp;&nbsp;<span>{registerLink}</span>
                    </div>
                    <p className={style.instructions}>
                        Copy the link above and share it with your employees to give them access to the online training.
                    </p>
                </div>
            )}
            <div>
                <h3>Employees</h3>
                {renderEmployeeStatement()}
                {data.employeeCount > 0 && (
                    <Table
                        name="OrganizationAdminDashboard"
                        rowKey="pawn.pawnId"
                        query={employeesQuery}
                        columns={columns}
                        graphqlVariables={{ deploymentId: data.courseDeploymentId }}
                    />
                )}
            </div>
        </div>
    );
};

export default OrganizationAdminDashboard;
