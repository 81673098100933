import copy from 'copy-to-clipboard';
import notification from 'cccisd-notification';

export const copyOnClick = url => {
    copy(url);

    notification({
        message: 'Copied to clipboard!',
        type: 'success',
        duration: 4000,
    });
};
