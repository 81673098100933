import React from 'react';
import IconCopy from 'cccisd-icons/copy3';
import Tooltip from 'cccisd-tooltip';
import { copyOnClick } from '../../helpers';
import style from './style.css';

const Env = window.cccisd.env;

export default settings => {
    return ({ value }) => {
        if (!value) {
            return <span className={style.cell}>-</span>;
        }

        const baseUrl = Env.url;
        const registerUrl = baseUrl.endsWith('/')
            ? baseUrl + 'register/' + value
            : baseUrl + '/register/' + value;

        return (
            <span className={style.cell}>
                <Tooltip title="Copy Link">
                    <a
                        href={registerUrl}
                        onClick={e => {
                            e.preventDefault();
                            copyOnClick(registerUrl);
                        }}
                    >
                        {'/' + value}
                    </a>
                </Tooltip>
                &nbsp;&nbsp;
                <Tooltip title="Copy Link">
                    <button
                        type="button"
                        className="btn btn-xs btn-info"
                        onClick={() => copyOnClick(registerUrl)}
                    >
                        <IconCopy />
                    </button>
                </Tooltip>
            </span>
        );
    };
};
