import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'cccisd-react-router';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import Footer from 'cccisd-footer';
import style from './style.css';
import NavBar from '../../components/NavBar';
import bgImage from './background.png';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showSecondaryNav: PropTypes.bool,
        location: PropTypes.object,
    };

    static defaultProps = {
        className: 'container',
        showSecondaryNav: false,
    };

    render() {
        const isBackground = this.props.location.pathname === '/dashboard';
        const bodyStyles = isBackground
            ? {
                  backgroundImage: `url(${bgImage})`,
                  padding: '20px 0',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
              }
            : {};

        return (
            <div className={style.wrapper}>
                <NavBar className={this.props.className} />
                <div className={style.body} style={bodyStyles}>
                    {this.props.showSecondaryNav && Fortress.auth() && (
                        <SecondaryNav
                            className={this.props.className}
                            navs={AppDefs.navs}
                            routes={AppDefs.routes}
                        />
                    )}
                    <div className={this.props.className}>
                        <div className={isBackground ? style.contentContainer : ''}>
                            {this.props.children}
                        </div>
                    </div>
                </div>

                <div className={style.footer}>
                    <Footer className={this.props.className} />
                </div>
            </div>
        );
    }
}

export default withRouter(MainLayout);
