import React, { useEffect, useState } from 'react';
import RegisterLoginPage from 'cccisd-laravel-nexus/dist/pages/RegisterLoginPage';
import { useParams, Redirect } from 'react-router-dom';
import groupQuery from './groups.graphql';
import { client } from 'cccisd-apollo';
import _get from 'lodash/get';
import Loader from 'cccisd-loader';

const Register = () => {
    const [data, setData] = useState(null);
    const [isLoading, setisLoading] = useState(true);

    let { registerHash } = useParams();

    const collectData = async () => {
        const response = await client.query({
            query: groupQuery,
            variables: {
                registerHash,
            },
        });
        const instructorCount = _get(
            response,
            'data.groups.site.descendantRoles.instructorCount',
            0
        );
        const groupId = _get(response, 'data.groups.site.group.groupId', 0);
        const label = _get(response, 'data.groups.site.group.label', '');
        const groupType = _get(response, 'data.groups.site.group.groupType', '');
        const createdDate = _get(response, 'data.groups.site.group.createdDate', 0);
        const employeeLimit = _get(response, 'data.groups.site.fields.employeeLimit', null);
        setData({ instructorCount, groupId, label, groupType, createdDate, employeeLimit });
    };

    if (registerHash === undefined) {
        return <Redirect to="/welcome" />;
    }

    useEffect(() => {
        collectData();
        setisLoading(false);
    }, []);

    if (isLoading || !data) {
        return <Loader loading />;
    }
    const registerLoginProps = {
        registerProps: {
            role: 'instructor',
            showPasswordFields: true,
            title: 'Register',
            group: data && data.groupId,
        },
        loginProps: { title: 'Login' },
    };

    if (!data.groupId) {
        return (
            <div className="alert alert-danger" role="alert">
                Thank you for your interest in One Step Ahead, a program of SAVE. This link is
                invalid. Please contact your human resources representative for additional support.
            </div>
        );
    }

    if (data.instructorCount >= data.employeeLimit && data.employeeLimit) {
        return (
            <div className="alert alert-danger" role="alert">
                Thank you for your interest in One Step Ahead, a program of SAVE. This training is
                closed to new participants. Please contact your human resources representative for
                additional support.
            </div>
        );
    }

    return <RegisterLoginPage {...registerLoginProps} />;
};

export default Register;
